<template>
    <v-container fluid class="pa-0">
        <v-row class="justify-start">
            <v-col cols="12">

                <v-alert v-if="!bMonate" type="info" color="primary" border="left" class="ma-4">
                    Es sind noch keine Belege vorhanden.
                </v-alert>
                <div v-else class="my-2">

                    <!-- Monatsauswahl -->
                    <v-tabs center-active show-arrows v-model="selectedMonat">
                        <v-tab v-for="bm in bMonate" :key="bm.monat" class="mx-4 text-h5 text-md-h4 flex-nowrap">
                            {{String(bm.monat).substring(2, String(bm.monat).length) + "/20" + String(bm.monat).substring(0, String(bm.monat).length - 2)}}
                        </v-tab>
                    </v-tabs>

                    <!-- Übersicht Retaxen -->
                    <v-tabs-items :value="selectedMonat" class="mt-3">
                        <v-tab-item v-for="bm in bMonate" :key="bm.monat">
                            <TabRetaxen :monat="bm.monat" />
                        </v-tab-item>
                    </v-tabs-items>

                </div>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import TabRetaxen from '@/components/TabRetaxen.vue'
    import eventBus from '@/main';

    export default {
        name: 'Retaxen',
        components: {
            TabRetaxen
        },

        data: () => ({
            length: 12,
            selectedMonatField: null,
            tabId: 0,
        }),

        computed: {
            bMonate() {
                return this.$store.state.BMonate;
            },

            selectedMonat: {
                get: function () {
                    if (this.selectedMonatField == null && this.bMonate != null)
                        this.selectedMonatField = this.bMonate.length - 1;

                    return this.selectedMonatField;
                },
                set: function (newValue) {
                    this.selectedMonatField = newValue;
                }
            }
        },

        watch: {
            selectedMonat() {
                /* Bei Tab-Wechsel (sicherheitshalber) store leeren und 
                 * Nachricht an alle Instanzen von TabRetaxen-Component
                 * zum Leeren der selektierten Belege senden.
                 */ 
                this.$store.commit('updateSelectedAbsetzungen', []);
                eventBus.$emit("clearSelectedAbsetzungen");
            },
        },
    }
</script>
