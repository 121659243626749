<template>
    <v-container v-if="$store.state.defCache" fluid class="px-4">
        <v-row class="align-center">
            <v-col cols="12" sm="6" md="4">
                <v-select label="Kostenträger" v-model="selectedMandant"
                          :items="userMandaten" item-text="bezeichnung" item-value="mandant"
                          :menu-props="{ maxHeight: '400', nudgeBottom: '30px' }"
                          :class="{ 'custom-dense': $vuetify.breakpoint.mdAndDown }" hide-details="auto" />

            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" class="text-h6" :disabled="!documentExists"
                       :href="documentUrl" :download="documentFilename" target="_blank"
                       :large="$vuetify.breakpoint.lgAndUp">

                    <v-icon :large="$vuetify.breakpoint.lgAndUp" left>
                        $vuetify.icons.pdf
                    </v-icon>
                    Anschreiben
                </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="showLeseBestaetigungWarnung">
                <v-alert dense
                         outlined
                         type="warning" class="mt-4">
                    {{leseBestaetigungWarnung}}
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="pr-4">
                <v-data-table v-model="selectedAbsetzungen" :headers="absetzungenHeaders" :items="absetzungen"
                              item-key="belegNr" :items-per-page="10" :page.sync="tablePage" :custom-sort="customSort"
                              @item-selected="selectAbsetzung" @click:row="showRetaxDetails"
                              :footer-props="{'items-per-page-options': [5, 10, 25, 50, -1]}"
                              :item-class="itemClass" :dense="$vuetify.breakpoint.mdAndDown"
                              class="font-weight-semibold text-no-wrap text-body-2 text-lg-body-1
                                scroll-gradient elevation-2 rounded-0 alternating-row-colors" show-select>
                    
                    <template v-for="h in absetzungenHeaders" v-slot:[`header.${h.value}`]="{ header }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <span v-on="on">{{h.text}}</span>
                            </template>
                            <span>{{h.tooltip}}</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.buchungBenutzerStatus="{ item }">
                        <v-icon v-if="item.isSelectable">
                            {{ buchungBenutzerStatusDef[item.buchungBenutzerStatus].icon }}
                        </v-icon>
                        <v-icon v-else>
                            $vuetify.icons.lock
                        </v-icon>
                    </template>
                    <template v-slot:item.belegNr="{ item }">
                        <v-tooltip v-if="item.tooltip && !showRetaxDetailDialog" bottom>
                            <template v-slot:activator="{ on }">
                                <span v-on="on">{{ item.belegNr }}</span>
                            </template>
                            <span>{{ item.tooltip }}</span>
                        </v-tooltip>
                        <span v-else>{{ item.belegNr }}</span>
                        <v-tooltip v-if="item.eRezept" bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" size="small" class="ml-2">
                                    mdi-alpha-e-circle
                                </v-icon>
                            </template>
                            <span>E-Rezept-ID: {{ item.eRezept }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.aDatum="{ item }">
                        <span>{{ moment(item.aDatum).format('DD.MM.YYYY') }}</span>
                    </template>
                    <template v-slot:item.einspruchLink="{ item }">
                        <v-tooltip v-if="!item.isSelectable && !showRetaxDetailDialog" bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn @click.stop.prevent="openEinspruchListe(item.belegNr)" v-on="tooltip" small icon>
                                    <v-icon color="primary">
                                        $vuetify.icons.textSearch
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Bitte anklicken, um die Einsprüche zum Beleg zu öffnen</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:footer.prepend>
                        <v-btn color="primary" :disabled="isEinspruchEinlegenDisabled"
                               class="d-none d-md-block flex-shrink-1 my-2 mr-4 v-btn-multiline text-h6"
                               @click="einspruchEinlegen" large>

                            <div>
                                <div class="d-inline-block">Einspruch für markierte&nbsp;</div>
                                <div class="d-inline-block">Belege einlegen</div>
                            </div>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row class="d-md-none">
            <v-col cols="auto">
                <v-btn color="primary" :disabled="isEinspruchEinlegenDisabled"
                       @click="einspruchEinlegen" class="v-btn-multiline mb-0 text-h6" x-large>

                    <div>
                        <div class="d-inline-block">Einspruch für markierte&nbsp;</div>
                        <div class="d-inline-block">Belege einlegen</div>
                    </div>
                </v-btn>
            </v-col>
        </v-row>

        <!-- Dialog Lesebestätigung -->
        <v-dialog v-model="showLeseBestaetigung" persistent>
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>mdi-check-circle-outline</v-icon>
                    Bestätigung für den Monat {{ monatPretty(this.monat) }}
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    <p class="text-justify text-hyphenate">
                        Hiermit bestätigen Sie, die Beanstandungen für alle Kostenträger in diesem Monat
                        eingesehen zu haben. Mit dem Zeitpunkt der Zustellung der E-Mail, dass die Beanstandungen
                        im Service-Portal eingestellt wurden, beginnt die Einspruchsfrist.
                    </p>
                    <p class="text-justify text-hyphenate mb-0">
                        Wenn Sie das Einsehen der Beanstandungen nun bestätigen, werden Ihnen keine weiteren
                        E-Mails zur Erinnerung zugesendet.
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" type="submit"
                           @click.stop.prevent="setLesebestaetigung">

                        Bestätigen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog Retax-Details -->
        <v-dialog ref="retaxDetailDialog" v-model="showRetaxDetailDialog"
                  transition="dialog-bottom-transition" @keydown.stop="onKeyPressed"
                  fullscreen>

            <v-card borderless white class="overflow-x-hidden">
                <v-container fluid>
                    <v-row id="wrap-toolbar" class="text-h6 text-center flex-sm-nowrap elevation-2">

                        <v-spacer class="order-sm-0" style="max-width: 40px;" />

                        <v-col cols="7" sm="auto" class="d-flex align-center justify-center order-sm-1 flex-sm-shrink-1">
                            Beleg: {{ currentBelegNr }}
                        </v-col>

                        <v-spacer class="order-sm-2" style="max-width: 80px;">
                            <v-divider vertical />
                        </v-spacer>

                        <v-col cols="3" sm="auto" class="d-flex align-center justify-center order-sm-3" style="min-width: 70px;">
                            {{ currentIndex + 1 }} / {{ sortedAbsetzungen.length }}
                        </v-col>

                        <v-spacer class="order-sm-4" style="max-width: 80px;">
                            <v-divider vertical />
                        </v-spacer>


                        <v-col cols="3" sm="auto" v-if="currentERezeptID" class="d-flex align-center justify-sm-end order-sm-9 flex-sm-shrink-1">
                            E-Rezept-ID: {{ currentERezeptID }}
                        </v-col>

                        <!--<v-spacer class="order-sm-10" style="max-width: 80px;">
                            <v-divider vertical />
                        </v-spacer>-->

                        <v-col cols="2" sm="auto" class="d-flex align-center justify-center justify-sm-end order-sm-last flex-sm-grow-1">
                            <v-btn icon color="black"
                                   @click="showRetaxDetailDialog = false">

                                <v-icon large>$vuetify.icons.close</v-icon>
                            </v-btn>
                        </v-col>

                        <v-spacer class="order-sm-6" style="max-width: 80px;">
                            <v-divider vertical />
                        </v-spacer>

                        <v-col cols="7" sm="auto" class="d-flex align-center justify-center order-sm-7" style="min-width: 172px;">

                            <!-- Status -->
                            <v-btn-toggle v-if="currentAbsetzung && !isEinspruchAktiv"
                                          v-model="currentAbsetzung.buchungBenutzerStatus"
                                          @change="buchungBenutzerStatusChanged"
                                          class="elevation-2" mandatory>

                                <v-tooltip v-for="item in buchungBenutzerStatusDef" :key="item.status"
                                           :disabled="!item.tooltip" content-class="pa-0 opacity-1" bottom>

                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" :value="item.status"
                                               :large="$vuetify.breakpoint.mdAndUp">

                                            <v-icon>{{ item.icon }}</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card max-width="350">
                                        <v-card-title>
                                            Tastenkürzel: {{ item.hotkey }}
                                        </v-card-title>
                                        <v-card-text class="text-body-1 text--primary">
                                            <p class="text-h4">
                                                {{ item.bezeichnung }}
                                            </p>
                                            <div class="font-weight-semibold">
                                                {{ item.tooltip }}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-tooltip>
                            </v-btn-toggle>

                            <!-- Gesperrt -->
                            <v-tooltip bottom v-if="isEinspruchAktiv">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon color="black"
                                           v-on="on" elevation="0">

                                        <v-icon>$vuetify.icons.lock</v-icon>
                                    </v-btn>
                                </template>
                                <v-card class="mx-auto" max-width="350">
                                    <v-card-text class="text-body-1 text--primary">
                                        <div class="font-weight-bold">Status kann nicht gesetzt werden</div>
                                        <p class="text-h4">
                                            Gesperrt
                                        </p>
                                        <div class="font-weight-semibold">
                                            Für diesen Beleg wurde schon ein Einspruch angelegt, der sich im Entscheidungsverfahren befindet
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-tooltip>
                        </v-col>

                        <v-spacer class="order-sm-8" style="max-width: 80px;">
                            <v-divider vertical />
                        </v-spacer>

                        <v-col cols="5" sm="auto" :class="[{ 'max-width-120': $vuetify.breakpoint.smAndUp },
                            'd-flex', 'align-center', 'justify-center', 'order-sm-5', 'flex-grow-1']">

                            <v-spacer class="d-sm-none" />
                            <v-btn icon color="black" class="mr-1"
                                   @click="gotoPrevAbsetzung">

                                <v-icon large>$vuetify.icons.arrowLeft</v-icon>
                            </v-btn>
                            <v-spacer />
                            <v-btn icon color="black" class="ml-1"
                                   @click="gotoNextAbsetzung">

                                <v-icon large>$vuetify.icons.arrowRight</v-icon>
                            </v-btn>
                            <v-spacer class="d-sm-none" />
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col>
                            <tab-retax-beleg-details :parentSelectedBeleg="currentAbsetzung" :BMonat="monat" :currentERezept="currentERezeptID"/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import TabRetaxBelegDetails from '@/components/TabRetaxBelegDetails.vue'
    import axios from 'axios'
    import eventBus from '@/main';

    export default {
        name: 'TabRetaxen',

        components: {
            TabRetaxBelegDetails
        },

        data: () => ({
            absetzungen: [],
            selectedAbsetzungen: [],
            sortedAbsetzungen: [],
            absetzungenHeaders: [
                { text: 'Status', value: 'buchungBenutzerStatus', align: 'center' },
                { text: 'Beleg-Nr', value: 'belegNr', align: 'start' },
                { text: 'Grund', value: 'retaxGrundBeschreibung', align: 'start' },
                { text: 'VersNr', value: 'versNr', align: 'start' },
                { text: 'Nachname', value: 'nachname', align: 'start' },
                { text: 'Vorname', value: 'vorname', align: 'start' },
                { text: 'LE-IK', value: 'leistungserbringer', align: 'start', tooltip: 'Leistungserbringer-IK' },
                { text: 'Abgabedatum', value: 'aDatum', align: 'end' },
                { text: 'Retax-Summe (€)', value: 'summe', align: 'end' },
                { text: '', value: 'einspruchLink', sortable: false },
            ],
            currentBelegNr: '',
            currentAbsetzung: {},
            selectedMandant: 1,
            tablePage: 1,
            documentExists: true,
            documentData: null,
            needsReload: false,
            showLeseBestaetigung: false,
            showLeseBestaetigungWarnung: false,
            showRetaxDetailDialog: false,
        }),

        props: ['monat'],

        mounted() {
            if (this.monat !== null) {
                this.getRetaxListe(this.monat);
                this.getLesebestaetigung(this.monat);
                this.getLesebestaetigungWarnung();
            }

            this.initData();
        },

        activated() {
            this.getLesebestaetigung(this.monat);
        },

        created() {
            // Alle ausgewählten Belege nach kurzer Verzögerung (nach Animation) leeren.
            eventBus.$on('clearSelectedAbsetzungen', () => { setTimeout(() => this.selectedAbsetzungen = [], 100); });

            // Wenn Einspruch erstellt wurde, dann Liste wg. gesperrten Belegen neu laden.
            eventBus.$on("EinspruchErstellt", () => { this.needsReload = true; });
        },

        watch: {

            needsReload(newValue) {
                if (newValue) {
                    this.initData();
                    this.getRetaxListe(this.monat);
                    this.needsReload = false;
                }
            },

            currentLE(newValue) {
                if (newValue)
                    this.needsReload = true;
            },

            selectedMandant() {
                if (this.monat !== null) {
                    this.initData();
                    this.getRetaxListe(this.monat);
                }
            },

            currentBelegNr(newValue) {
                this.currentAbsetzung = this.absetzungen.find(x => x.belegNr == newValue);
            },



            '$store.state.leseBestaetigungWarnung'() {
                this.showLeseBestaetigungWarnung = this.$store.state.leseBestaetigungWarnung.length > 1? true : false;
            },

            showRetaxDetailDialog(newValue) {

                // Verhindert, dass manchmal mehrere Scrollbalken nebeneinander erscheinen
                // (overflowY = 'scroll' ist vuetify default)
                let overflowY = newValue === true ? 'hidden' : 'scroll';
                document.querySelector("html").style.overflowY = overflowY;
            },

        },

        computed: {

            currentLE() {
                return this.$store.state.currentUser?.leistungserbringer;
            },

            userMandaten() {
                return this.$store.state.currentUser?.mandanten;
            },

            buchungBenutzerStatusDef() {

                const defName = 'buchungBenutzerStatus';
                const defTable = this.getDefTable(defName);

                // array => hash map (object)
                return defTable.reduce((map, def) => {
                    map[def.BenutzerStatus] = {
                        status: def.BenutzerStatus,
                        bezeichnung: def.Bezeichnung,
                        tooltip: def.Beschreibung,
                        icon: (_ => {
                            switch (def.BenutzerStatus) {
                                case 0: return this.$vuetify.icons.values.new;
                                case 1: return this.$vuetify.icons.values.ok;
                                case 2: return this.$vuetify.icons.values.faq;
                            };
                        })(),
                        hotkey: (_ => {
                            switch (def.BenutzerStatus) {
                                case 0: return 'N';
                                case 1: return 'O';
                                case 2: return 'E';
                            }
                        })()
                    };
                    return map;
                }, {});
            },

            isEinspruchEinlegenDisabled() {
                const rechtSchreiben = 2;
                const benutzerRechte = this.$store.state.currentUser?.rechte;

                return this.selectedAbsetzungen?.length <= 0
                    || benutzerRechte?.filter(r => r.recht === rechtSchreiben).length <= 0
                    || !this.$store.state.module.retaxWrite;
            },

           
            currentIndex() {
                if (this.sortedAbsetzungen)
                    return this.sortedAbsetzungen.findIndex(x => x.belegNr == this.currentBelegNr);
                else
                    return 0;
            },

            currentERezeptID() {
                let ab = this.sortedAbsetzungen[this.currentIndex];
                return ab ? ab['eRezept'] ?? (ab['eRezept'] = null) : null;
            },

            isEinspruchAktiv() {
                return this.currentAbsetzung && this.currentAbsetzung.kzBearbeitung === 0;
            },

            documentUrl() {
                if (!this.documentData)
                    return null;

                let mimeType = 'application/pdf';

                /* WORKAROUND:
                 * Firefox 98|99|100 öffnet pdf mit angegebenem download="abcd.pdf" Attribut mittels des internen Viewers
                 * im SELBEN Tab, obwohl ein target="_blank" gesetzt wurde. Davon abgesehen weicht die Implementation
                 * bzw. das Verhalten des download Attributes z.B. von Chrome ab. Siehe BUG:
                 * https://bugzilla.mozilla.org/show_bug.cgi?id=1756980
                 */
                let needsFix = new RegExp('Firefox/(98|99|100)(\.[0-9])*', 'i').test(navigator.userAgent);
                if (needsFix)
                    mimeType = 'application/octet-stream';

                let blob = new Blob([this.documentData], { type: mimeType });
                return URL.createObjectURL(blob);
            },

            documentFilename() {
                let mandant = this.$store.state.defCache.mandant.find(x => x.Mandant == this.selectedMandant).Kurzbezeichnung;
                return `${mandant}_20${this.monat}_Absetzungen.pdf`;
            },

            leseBestaetigungWarnung() {
                return this.$store.state.leseBestaetigungWarnung;
             }

        },

        methods: {

            getDefTable(defName) {
                return this.$store.state?.defCache?.[defName]
                    || console.error(`Definition "${defName}" wurde nicht gefunden!`);
            },

            onKeyPressed(e) {
                let newStatus = null;
                let navCallback = null;

                switch (e.keyCode) {
                    case 78: // 'n'
                        newStatus = 0;
                        break;

                    case 79: // 'o'
                        newStatus = 1;
                        break;

                    case 69: // 'e'
                        newStatus = 2;
                        break;

                    case 37: // left
                    case 33: // pageUp
                        navCallback = this.gotoPrevAbsetzung;
                        break;

                    case 39: // right
                    case 34: // pageDown
                        navCallback = this.gotoNextAbsetzung;
                        break;

                    case 32: // Leertaste
                        newStatus = 1;
                        navCallback = this.gotoNextAbsetzung;
                        break;
                }

                if (!this.isEinspruchAktiv && newStatus != null) {
                    this.currentAbsetzung.buchungBenutzerStatus = newStatus;
                    this.buchungBenutzerStatusChanged();
                }

                navCallback && navCallback();
            },

            buchungBenutzerStatusChanged() {

                // Wenn für Einspruch markiert, dann gleich mit selektieren.
                if (this.currentAbsetzung.buchungBenutzerStatus == 2)
                    this.selectedAbsetzungen.push(this.currentAbsetzung);

                this.$http.post('/retax/buchungBenutzerStatus', {
                    BuchungID: this.currentAbsetzung.buchungID,
                    Status: this.currentAbsetzung.buchungBenutzerStatus,
                });
            },

            gotoNextAbsetzung() {
                if (this.currentIndex < this.sortedAbsetzungen.length - 1)
                    this.currentBelegNr = this.sortedAbsetzungen[this.currentIndex + 1].belegNr; // triggert auch this.currentAbsetzung wegen watch
            },

            gotoPrevAbsetzung() {
                if (this.currentIndex > 0)
                    this.currentBelegNr = this.sortedAbsetzungen[this.currentIndex - 1].belegNr; // triggert auch this.currentAbsetzung wegen watch
            },

            openEinspruchListe(beleg) {
                this.currentBelegNr = beleg;
                eventBus.$emit('resetLogoutTimer', {});

                this.$router.push({
                    name: 'Einspruchsliste',
                    params: { belegNr: beleg }
                });
            },

            customSort: function (items, index, isDesc) {
                items.sort((a, b) => {
                    // Beim Feld 'Summe' muss der Wert zum Sortieren in eine Zahl konvertiert werden
                    if (index[0] == 'summe') {
                        if (!isDesc[0]) {
                            return Number(b[index].replace(/[,.]/g, '')) - Number(a[index].replace(/[,.]/g, ''));
                        } else {
                            return Number(a[index].replace(/[,.]/g, '')) - Number(b[index].replace(/[,.]/g, ''));
                        }
                    }
                    else if (index[0] == 'buchungBenutzerStatus') {
                        if (!isDesc[0]) {
                            return Number(b.isSelectable ? b[index] : -1) - Number(a.isSelectable ? a[index] : -1);
                        } else {
                            return Number(a.isSelectable ? a[index] : -1) - Number(b.isSelectable ? b[index] : -1);
                        }
                    }
                    // alle anderen Felder werden alphabetisch sortiert
                    else {
                        if (!isDesc[0]) {
                            return ((a[index] == b[index]) ? 0 : ((a[index] > b[index]) ? 1 : -1));
                        }
                        else {
                            return ((a[index] == b[index]) ? 0 : ((a[index] > b[index]) ? -1 : 1));
                        }
                    }
                });

                this.sortedAbsetzungen = items;
                return items;
            },

            initData() {
                this.selectedAbsetzungen = [];
                this.documentExists = true;
                this.documentData = null;


            },

            showRetaxDetails(value) {
                this.currentBelegNr = value.belegNr;
                this.showRetaxDetailDialog = true;

                /* Scheint einzige Möglichkeit zu sein, den Fokus für KeyEvents beim
                 * (erstmaligen?) Öffnen zuverlässig in Dialog zu verschieben.
                 */
                this.$nextTick(() => {
                    this.$refs.retaxDetailDialog.show();
                });
            },

            selectAbsetzung(a) {
                this.absetzungen.find(x => x.belegNr == a.item.belegNr).selected = a.value;
            },

            einspruchEinlegen() {
                eventBus.$emit('resetLogoutTimer', {});
                let absetzungen = this.selectedAbsetzungen.filter(a => a.mandant === this.selectedMandant);

                this.$store.commit('updateSelectedAbsetzungen', absetzungen);
                this.$router.push('einspruchadd');
            },

            convertToEuro(value) {
                return new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value / 100);
            },

            loadRetaxDocument() {
                const le = this.$store.state.currentUser.leistungserbringer;
                this.documentData = null;
                axios({
                    method: 'get',
                    url: 'retax/document',
                    responseType: 'arraybuffer',
                    params: {
                        Leistungserbringer: le,
                        BM: this.monat, // entspricht Buchungsmonat
                        Mandant: this.selectedMandant
                    }
                }).then((response) => {
                    if (response.status == 200) {
                        this.documentExists = true; //Dokument ist vorhanden
                        this.documentData = response.data;
                    }
                    else {
                        this.documentExists = false;
                    }
                }).catch(error => {
                    this.documentExists = false; ////Dokument ist nicht vorhanden
                    console.log('Fehler beim Downloaden der Datei: ', error.response, error.response.data.message);
                });
            },

            getRetaxListe(monat) {

                //const vm = this;
                const le = this.$store.state.currentUser.leistungserbringer;
                this.absetzungen = [];

                this.$http.get('/retax/monatsuebersicht', {
                    params: {
                        Leistungserbringer: le,
                        BuchungMonat: monat,
                        Mandant: this.selectedMandant
                    }
                }).then((response) => {
                    if (response.status === 200) {

                        //RetaxDokument laden
                        this.loadRetaxDocument();

                        //Belegliste laden
                        response.data.forEach(ep => {

                            // Nicht selektierbar, falls es einen laufenden Einspruch gibt.
                            const isSelectable = ep.kzBearbeitung !== 0;
                            const selected = false;

                            // Tooltip nur für Belege mit laufendem Einspruch
                            const tooltip = ep.kzBearbeitung === 0 ? this.$store.state.defCache.kZBearbeitung[ep.kzBearbeitung].Beschreibung : '';
                            this.absetzungen.push({
                                ...ep,
                                summe: this.convertToEuro(ep.summe),
                                isSelectable: isSelectable,
                                selected: selected,
                                tooltip: tooltip,
                            });
                        });

                        this.tablePage = 1;
                        this.sortedAbsetzungen = this.absetzungen.sort();

                          if (this.$store.state.selectedAbsetzungen.length && this.$store.state.selectedAbsetzungen.some(r => r.mandant === this.selectedMandant)) {
                            var absetzungenAusStore = this.$store.state.selectedAbsetzungen;
                            absetzungenAusStore.forEach(a => {
                                var match = this.absetzungen.find(e => e.belegNr === a.belegNr);
                                if (typeof match != 'undefined') {
                                    this.selectedAbsetzungen.push(match);
                                }
                            });
                        }
                    }
                }).catch(error => {
                    if (error.response) {
                        this.$store.commit('updateCurrentError', error.response.data);
                    }
                });
            },

            monatPretty(monat) {
                let m = monat; //this.monat;
                return String(m).substring(2, String(m).length) + "/20" + String(m).substring(0, String(m).length - 2);
            },

            getLesebestaetigung() {

                const le = this.$store.state.currentUser.leistungserbringer;
                this.$http.get('/retax/lesedatum', {
                    params: {
                        Leistungserbringer: le,
                        BM: this.monat
                    }
                }).then((response) => {
                    if (response.status === 200 && response.data) {
                        if (response.data.leseDatum)
                            console.log('Die Absetzungen des Monats ' + this.monat + ' wurden bereits eingesehen am: ' + response.data.leseDatum);
                        else
                            this.showLeseBestaetigung = response.data.kZ_MA === true;
                    }
                }).catch(error => {
                    if (error.response) {
                        this.$store.commit('updateCurrentError', error.response.data);
                    }
                });
            },

            getLesebestaetigungWarnung() {

                const le = this.$store.state.currentUser.leistungserbringer;
                this.showLeseBestaetigungWarnung = false
                let lBestaetigungWarnung = "";
                this.$http.get('/retax/lesedatumwarnung', {
                    params: {
                        Leistungserbringer: le
                    }
                }).then((response) => {
                    if (response.status === 200 && response.data) {
                        let r = response.data;

                        //prüfen, ob aktuelle Monat in der Liste, der nicht lesebestätigten Beanstandungen ist
                        //wenn ja, dann keine Warnung ausgeben 
                        let ix = r.findIndex(x => x === this.monat);
                        if (ix != -1)
                            return

                        if (r.length === 1 && r[0] !== this.monat) {
                            lBestaetigungWarnung = "Sie haben die Beanstandungen im Monat " + this.monatPretty(r[0]) + " noch nicht gelesen.";
                            this.showLeseBestaetigungWarnung = lBestaetigungWarnung.length > 1 ? true: false;
                            
                        }
                        else if (r.length > 1) {
                            let t = " und ";
                            r.forEach(x => {
                                if (x !== this.monat) { 
                                    lBestaetigungWarnung += this.monatPretty(x) + t;
                                    //console.log('this.LeseBestaetigungWarnung: ', lBestaetigungWarnung);
                                }

                            });
                            lBestaetigungWarnung = "Sie haben die Beanstandungen in den Monaten " + lBestaetigungWarnung.slice(0, -t.length) + " noch nicht gelesen."; //this.LeseBestaetigungWarnung.substr(0, this.LeseBestaetigungWarnung.length - t.length);
                            //console.log('this.LeseBestaetigungWarnung: final ', lBestaetigungWarnung, -t.length);
                            this.showLeseBestaetigungWarnung = lBestaetigungWarnung.length > 1 ? true : false;
                            
                        }
                    }
                    this.$store.commit('updateLeseBestaetigungWarnung', lBestaetigungWarnung);
                }).catch(error => {
                    if (error.response) {
                        this.$store.commit('updateCurrentError', error.response.data);
                    }
                });
            },

            setLesebestaetigung() {
                const le = this.$store.state.currentUser.leistungserbringer;
                this.showLeseBestaetigung = false;
                this.$http.post('/retax/lesedatum', {
                    Leistungserbringer: le,
                    BM: this.monat
                }).then((response) => {
                    if (response.status === 200) {
                        this.getLesebestaetigungWarnung();
                        console.log('Das Einsehen der Absetzungen des Monats ' + this.monat + ' wurde bestätigt.');
                    }
                }).catch(error => {
                    if (error.response) {
                        this.$store.commit('updateCurrentError', error.response.data);
                    }
                });
            },

            itemClass: function (item) {

                let cls = ['retax-row'];
 
                if (!item.isSelectable)
                    cls.push('grey--text');

                if (item.belegNr === this.currentBelegNr)
                    cls.push('highlighted');

                return cls;
            }
        },
    }
</script>
<style scoped>

    /*#region toolbar in retaxDetailDialog*/
    #wrap-toolbar > div {
        height: 68px;
        border-bottom: solid thin rgba(0, 0, 0, 0.12);
    }

    #wrap-toolbar .max-width-120 {
        max-width: 120px;
    }
    /*#endregion*/

    /*#region Pointer Cursor über Tabellenzeilen*/
    >>> .retax-row {
        cursor: pointer;
    }
    /*#endregion*/

    /*#region footer in v-data-table nur bei xs wrappen*/
    >>> .v-data-footer {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    @media (min-width: 600px) {
        >>> .v-data-footer {
            flex-wrap: nowrap;
        }
    }
    /*#endregion*/

    /*#region Bei mobile-Ansicht select-Checkbox in erster Zeile links ausrichten */
    >>> .v-data-table--mobile .v-data-table__mobile-row:first-child {
        flex-direction: row-reverse;
    }
    /*#endregion*/
    
</style>