<template>
    <v-container fluid class="pa-0" v-if="parentSelectedBeleg && versDaten">
        <v-row dense class="justify-center">
            <v-col cols="12" class="text-h4 pa-4">
                <div class="d-inline-block">Versichertennummer:&nbsp;</div>
                <div class="d-inline-block">{{ versDaten.versNr }}&nbsp;</div>
                <div class="d-inline-block">({{ versDaten.nachname }}, {{ versDaten.vorname }})</div>
            </v-col>
            
            <v-col cols="12" class="order-md-1 pr-6">
                <v-card borderless>
                    <v-card-title class="pt-2 text-h5">
                        Korrekturgründe
                    </v-card-title>
                    <v-card-text class="text-body-1 text--primary">
                        <v-data-table :headers="grund_headers" :items="gruende" item-key="position"
                                      mobile-breakpoint="0" class="text-body-2 font-weight-semibold elevation-2"
                                      disable-filtering disable-pagination disable-sort
                                      hide-default-header hide-default-footer />

                    </v-card-text>
                </v-card>
            </v-col>
            
            <v-col cols="12" md="6" lg="7" class="order-md-3 pr-6">
                <v-card borderless>
                    <v-card-title class="pt-2 text-h5">
                        Differenzübersicht
                    </v-card-title>
                    <v-card-text class="text-body-1 text--primary">
                        <v-data-table :headers="differenz_headers" :items="differenzen" item-key="position"
                                      :dense="$vuetify.breakpoint.smAndDown" 
                                      mobile-breakpoint="0" class="text-body-2 font-weight-semibold elevation-2"
                                      disable-filtering disable-pagination disable-sort 
                                      hide-default-header hide-default-footer />  
                        
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" sm="9" md="6" lg="5" class="order-md-2">
                <v-card borderless>
                    <v-card-text class="text-body-1 text--primary">
                        <v-hover v-if="imgFound">
                            <template v-slot:default="{ hover }">
                                <v-img :src="'data:image/png;base64,'+ belegImage" contain>
                                    
                                    <v-img v-if="currentERezept" v-show="showMuster16" src="../assets/erezept.png"
                                           class="fill-height muster16" cover />

                                    <v-img v-else v-show="showMuster16" src="../assets/muster16.png"
                                           class="fill-height muster16" cover />

                                    <v-fade-transition>
                                        <v-overlay v-if="hover" absolute>
                                            <v-btn @click="showDialogImg = true">Vollbild</v-btn>
                                        </v-overlay>
                                    </v-fade-transition>
                                </v-img>
                            </template>
                        </v-hover>
                        <v-alert v-else type="warning" border="left">
                            Zu diesem Beleg ist kein Rezeptbild verfügbar.
                        </v-alert>

                        <v-checkbox v-if="currentERezept" v-model="showMuster16" label="Muster E-Rezept einblenden"
                                    class="mt-3" hide-details />

                        <v-checkbox v-else v-model="showMuster16" label="Muster 16 einblenden"
                                    class="mt-3" hide-details />

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- Dialog Vergrößertes Image -->
        <v-dialog v-model="showDialogImg" fullscreen>
            <v-container fluid class="fill-height pa-0 white">
                <v-img :src="'data:image/png;base64,'+ belegImage" @click="showDialogImg = false" class="fill-height" contain>
                    <v-img v-if="currentERezept" v-show="showMuster16" src="../assets/erezept.png" class="fill-height muster16" contain />
                    <v-img v-else v-show="showMuster16" src="../assets/muster16.png" class="fill-height muster16" contain />
                </v-img>
            </v-container>
        </v-dialog>

    </v-container>
</template>

<script>
    export default {
        name: 'TabRetaxBelegDetails',

        data: () => ({
            showDialogImg: false,
            showMuster16: true,
            imgFound: false,
            versDaten: null,
            differenzen: [],
            differenz_headers: [
                { text: '', value: 'position', align: 'start' },
                { text: '', value: 'betrag', align: 'end' },
            ],

            gruende: [],
            grund_headers: [
                { text: '', value: 'position', align: 'start' },
                { text: '', value: 'betrag', align: 'end' },
                { text: '', value: 'korrekturGrund', align: 'start' },
            ],

            belegImage: null,
        }),

        props: {
            parentSelectedBeleg: Object,
            BMonat: Number,
            currentERezept: String
        },

        mounted() {
            if (this.parentSelectedBeleg) {
                this.getBelegDetails(this.parentSelectedBeleg, this.BMonat);
            }
        },

        watch: {
            parentSelectedBeleg() {
                if (this.parentSelectedBeleg) {
                    this.getBelegDetails(this.parentSelectedBeleg, this.BMonat);
                }
            },
        },

        methods: {
            getBelegDetails(beleg, monat) {
                const vm = this;
                const le = this.$store.state.currentUser.leistungserbringer;

                this.versDaten = null;
                this.gruende = [];
                this.differenzen = [];
                this.belegImage = null;
                this.isBusy = true;

                this.$http.get('retax/belegdetails', {
                    params: {
                        Leistungserbringer: le,
                        BM: monat,
                        BelegNr: beleg.belegNr,
                    }
                }).then((response) => {
                    if (response.status === 200) {
                        var result = response.data;

                        this.versDaten = result.versDaten;
                        this.gruende = result.belegKorrekturGrund.map(e => {
                            return {
                                position: e.position,
                                betrag: this.convertToEuro(e.betrag),
                                korrekturGrund: e.korrekturGrund
                            }
                        });
                        this.differenzen = result.differenzen.map(e => {
                            return {
                                position: e.position,
                                betrag: this.convertToEuro(e.betrag)
                            }
                        });

                        this.imgFound = (result.belegImageByte != undefined);
                        if (result.belegImageByte != undefined)
                            this.belegImage = result.belegImageByte;
                    }
                }).catch(error => {
                    if (error.response) {
                        this.$store.commit('updateCurrentError', error.response.data);
                    }
                });
            },

            convertToEuro(value) {
                return new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value / 100)
            },
        }
    }
</script>

<style scoped>

    .muster16 {
        opacity: 0.4;
    }

    .my-overlay >>> .v-overlay__content {
        width: 100%;
        height: 100%;
    }

</style>
